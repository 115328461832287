.App {
  text-align: center;
  height: 100%;
  width: 100%;   
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets//pic/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

